@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400&display=swap');
@import '~@csstools/normalize.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'Heebo', sans-serif;
}
