@import url(https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400&display=swap);
.styles_messageWrapper__2w9Cc {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  min-width: 100%;
}

.styles_messageContainer__2nXJI {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1366px;
  padding: 16px;
  z-index: 5;
}

.styles_messageContainer__2nXJI > div:first-child {
  order: 2;
}

.styles_messageContainer__2nXJI > div > h2 {
  font-size: 40px;
  line-height: 58.75px;
}

@media (max-width: 470px) {
  .styles_messageContainer__2nXJI > div > h2 > span:before {
    content: '\A';
    white-space: pre;
  }
}

@media (max-width: 1027px) {
  .styles_messageContainer__2nXJI > div > h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .styles_messageContainer__2nXJI > div > h2 {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .styles_messageContainer__2nXJI {
    align-items: center;
    flex-direction: row;
  }

  .styles_messageContainer__2nXJI > div:first-child {
    max-width: 40%;
    order: 0;
  }

  .styles_messageContainer__2nXJI > div:last-child {
    width: 54%;
  }
}

.styles_messageContainer__2nXJI > div > svg {
  width: 100%;
  height: auto;
}

.styles_messageTitle__2et2f {
  color: #4d5062;
  font-family: 'Heeboo', sans-serif;
  font-size: 25px;
  font-weight: bold;
}

.styles_messageTitle__2et2f span {
  color: #a8c038;
}

@media (min-width: 1024px) {
  .styles_messageTitle__2et2f {
    font-size: 37px;
    white-space: pre-line;
  }
}

.styles_messageText__1HjbC {
  color: #4d5062;
  font-family: 'Heeboo', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.styles_messageText__1HjbC span {
  color: #693fcf;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.styles_messageText__1HjbC b {
  color: #6045af;
}

@media (min-width: 1024px) {
  .styles_messageText__1HjbC {
    line-height: 1.5;
  }
}

.styles_messageLink__3qy9d {
  background-color: #693fcf;
  border-radius: 30px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Heeboo', sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  margin-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  text-transform: uppercase;
  transition: background 0.3s;
}

.styles_messageLink__3qy9d:hover {
  background-color: #8459b9;
}

@media (min-width: 1024px) {
  .styles_messageLink__3qy9d {
    min-width: 60%;
  }
}

.styles_alignEnd__32-zj {
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-end;
}

.styles_donut__2RxwC {
  height: 27vw;
  width: 44vw;
}

@media (min-width: 768px) {
  .styles_donut__2RxwC {
    height: 18vw;
    width: 32vw;
  }
}

@media (min-width: 1024px) {
  .styles_donut__2RxwC {
    height: 10vw;
    width: 20vw;
  }
}

.styles_dots__3e7Ky {
  height: 22vw;
  width: 30vw;
}

@media (min-width: 768px) {
  .styles_dots__3e7Ky {
    height: 20vw;
  }
}

@media (min-width: 1024px) {
  .styles_dots__3e7Ky {
    height: 7vw;
    width: 20vw;
  }
}

.styles_rowStart__9oq56 {
  display: flex;
  justify-content: space-between;
}

.styles_worms__1uWok {
  height: 20vw;
  margin-right: 10vw;
  margin-top: 2vw;
  width: 40vw;
}

@media (min-width: 768px) {
  .styles_worms__1uWok {
    height: 14vw;
    width: 25vw;
  }
}

@media (min-width: 1024px) {
  .styles_worms__1uWok {
    height: 6vw;
    margin-right: 10vw;
    margin-top: 7vw;
    width: 20vw;
  }
}

@media (min-width: 1440px) {
  .styles_worms__1uWok {
    margin-right: 17vw;
    margin-top: 5vw;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'Heebo', sans-serif;
}

