.messageWrapper {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  min-width: 100%;
}

.messageContainer {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1366px;
  padding: 16px;
  z-index: 5;
}

.messageContainer > div:first-child {
  order: 2;
}

.messageContainer > div > h2 {
  font-size: 40px;
  line-height: 58.75px;
}

@media (max-width: 470px) {
  .messageContainer > div > h2 > span:before {
    content: '\A';
    white-space: pre;
  }
}

@media (max-width: 1027px) {
  .messageContainer > div > h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .messageContainer > div > h2 {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .messageContainer {
    align-items: center;
    flex-direction: row;
  }

  .messageContainer > div:first-child {
    max-width: 40%;
    order: 0;
  }

  .messageContainer > div:last-child {
    width: 54%;
  }
}

.messageContainer > div > svg {
  width: 100%;
  height: auto;
}

.messageTitle {
  color: #4d5062;
  font-family: 'Heeboo', sans-serif;
  font-size: 25px;
  font-weight: bold;
}

.messageTitle span {
  color: #a8c038;
}

@media (min-width: 1024px) {
  .messageTitle {
    font-size: 37px;
    white-space: pre-line;
  }
}

.messageText {
  color: #4d5062;
  font-family: 'Heeboo', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.messageText span {
  color: #693fcf;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.messageText b {
  color: #6045af;
}

@media (min-width: 1024px) {
  .messageText {
    line-height: 1.5;
  }
}

.messageLink {
  background-color: #693fcf;
  border-radius: 30px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Heeboo', sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  margin-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  text-transform: uppercase;
  transition: background 0.3s;
}

.messageLink:hover {
  background-color: #8459b9;
}

@media (min-width: 1024px) {
  .messageLink {
    min-width: 60%;
  }
}

.alignEnd {
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-end;
}

.donut {
  height: 27vw;
  width: 44vw;
}

@media (min-width: 768px) {
  .donut {
    height: 18vw;
    width: 32vw;
  }
}

@media (min-width: 1024px) {
  .donut {
    height: 10vw;
    width: 20vw;
  }
}

.dots {
  height: 22vw;
  width: 30vw;
}

@media (min-width: 768px) {
  .dots {
    height: 20vw;
  }
}

@media (min-width: 1024px) {
  .dots {
    height: 7vw;
    width: 20vw;
  }
}

.rowStart {
  display: flex;
  justify-content: space-between;
}

.worms {
  height: 20vw;
  margin-right: 10vw;
  margin-top: 2vw;
  width: 40vw;
}

@media (min-width: 768px) {
  .worms {
    height: 14vw;
    width: 25vw;
  }
}

@media (min-width: 1024px) {
  .worms {
    height: 6vw;
    margin-right: 10vw;
    margin-top: 7vw;
    width: 20vw;
  }
}

@media (min-width: 1440px) {
  .worms {
    margin-right: 17vw;
    margin-top: 5vw;
  }
}
